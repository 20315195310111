import Images from "../assets/images";

const dataFake = [
  {
    name: "bột giặt",
    nameImage: "botgiat",
    option: "1",
    // image: {
    //   uri: Images.botgiat,
    //   sizeMultiplier: 0.7,
    //   offsetY: 150,
    // },
  },
  {
    name: "xe máy",
    nameImage: "xemay",
    option: "2",
    // image: {
    //   uri: Images.xemay,
    //   sizeMultiplier: 0.7,
    //   offsetY: 150,
    // },
  },
  {
    name: "khăn tắm",
    nameImage: "khantam",
    option: "3",
    // image: {
    //   uri: Images.khantam,
    //   sizeMultiplier: 0.5,
    //   offsetY: 150,
    // },
  },
  {
    name: "tăm bông",
    nameImage: "tambong",
    option: "4",
    // image: {
    //   uri: Images.tambong,
    //   sizeMultiplier: 0.5,
    //   offsetY: 150,
    // },
  },
  {
    name: "100.000 đồng",
    nameImage: "tienmat100k",
    option: "Mất lượt",
    style: { backgroundColor: '#AFAFAF' },
    // image: {
    //   uri: Images.tienmat100k,
    //   sizeMultiplier: 0.4,
    //   offsetY: 150,
    // },
  },
  {
    name: "100.000 đồng",
    nameImage: "tienmat100k",
    option: "6",
    // image: {
    //   uri: Images.tienmat100k,
    //   sizeMultiplier: 0.4,
    //   offsetY: 150,
    // },
  },
  {
    name: "200.000 đồng",
    nameImage: "tienmat200k",
    option: "7",
    // image: {
    //   uri: Images.tienmat200k,
    //   sizeMultiplier: 0.4,
    //   offsetY: 150,
    // },
  },
  {
    name: "500.000 đồng",
    nameImage: "tienmat500k",
    option: "Mất lượt",
    style: { backgroundColor: '#AFAFAF' },
    // image: {
    //   uri: Images.tienmat500k,
    //   sizeMultiplier: 0.4,
    //   offsetY: 150,
    // },
  },
  // {
  //   name: "xe máy",
  //   nameImage: "xemay",
  //   option: "9",
  //   // image: {
  //   //   uri: Images.xemay,
  //   //   sizeMultiplier: 0.7,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "khăn tắm",
  //   nameImage: "khantam",
  //   option: "10",
  //   // image: {
  //   //   uri: Images.khantam,
  //   //   sizeMultiplier: 0.5,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "tăm bông",
  //   nameImage: "tambong",
  //   option: "11",
  //   // image: {
  //   //   uri: Images.tambong,
  //   //   sizeMultiplier: 0.5,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "100.000 đồng",
  //   nameImage: "tienmat100k",
  //   option: "12",
  //   // image: {
  //   //   uri: Images.tienmat100k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "100.000 đồng",
  //   nameImage: "tienmat100k",
  //   option: "13",
  //   // image: {
  //   //   uri: Images.tienmat100k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "200.000 đồng",
  //   nameImage: "tienmat200k",
  //   option: "14",
  //   // image: {
  //   //   uri: Images.tienmat200k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "500.000 đồng",
  //   nameImage: "tienmat500k",
  //   option: "15",
  //   // image: {
  //   //   uri: Images.tienmat500k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "200.000 đồng",
  //   nameImage: "tienmat200k",
  //   option: "16",
  //   // image: {
  //   //   uri: Images.tienmat200k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "500.000 đồng",
  //   nameImage: "tienmat500k",
  //   option: "17",
  //   // image: {
  //   //   uri: Images.tienmat500k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "200.000 đồng",
  //   nameImage: "tienmat200k",
  //   option: "18",
  //   // image: {
  //   //   uri: Images.tienmat200k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "500.000 đồng",
  //   nameImage: "tienmat500k",
  //   option: "19",
  //   // image: {
  //   //   uri: Images.tienmat500k,
  //   //   sizeMultiplier: 0.4,
  //   //   offsetY: 150,
  //   // },
  // },
  // {
  //   name: "bột giặt",
  //   nameImage: "botgiat",
  //   option: "20",
  //   // image: {
  //   //   uri: Images.botgiat,
  //   //   sizeMultiplier: 0.7,
  //   //   offsetY: 150,
  //   // },
  // },
];

export default dataFake;
