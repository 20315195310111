import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import anime from 'animejs';
import { RouterProvider } from 'react-router-dom';
import router from './routers';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './translate';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </I18nextProvider>
  );
}

export default App;
