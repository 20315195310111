import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Col, Form, List, Modal, Row, Select } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { MdClose } from 'react-icons/md';

import Ic_location from '../../../../assets/icons/icon_location';

import { api } from '../../../../service';
import useArea from './useArea';
import VirtualList from 'rc-virtual-list';
// import FlatList from 'flatlist-react';
// import InfiniteScroll from 'react-infinite-scroller';
import './styles.scss';

interface ModalPageProps {
  title: string;
  onClose: () => void;
  onConFirm: (value: any) => void;
}

const OrderModal = (
  { title, onClose, onConFirm }: ModalPageProps,
  ref: any,
) => {
  const [form] = Form.useForm();
  const [cities, setCities] = useState<any[]>([]);
  const [wards, setWards] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const searchBody = useRef({});
  const condition = useRef({
    isLoading: true,
    canLoadMore: true,
    currentPage: 1,
  });
  const [listBoxes, setListBoxes] = useState<any[]>([]);
  useImperativeHandle(
    ref,
    () => {
      return {
        show: () => {
          setIsOpen(true);
          document.body.style.overflow = 'hidden';
        },
        hide: () => {
          setIsOpen(false);
          document.body.style.overflow = 'auto';
          form.resetFields();
        },
      };
    },
    [],
  );

  const handelConfirm = (item: any) => {
    onConFirm(item);
  };
  async function getListCities() {
    const response = await api.getListCity();
    setCities(response?.data?.items);

    if (response?.errorCode === 0) {
      setCities(response?.data?.items);
    } else {
      return [];
    }
  }

  useEffect(() => {
    getListCities();
    fetchListBox();
  }, []);

  async function getListDistrict(cityId: Array<string | undefined> = []) {
    const response = await api.getListDistrict(cityId);
    if (response.ok) {
      return response.data?.data.items;
    } else {
      return [];
    }
  }

  async function getListWard(query: Array<string | undefined> = []) {
    const { ok, data } = await api.getListWard(query);
    if (ok) {
      return data?.data.items;
    } else {
      return [];
    }
  }

  async function onChangeCity(value: any, callback?: () => void) {
    callback && callback();

    searchBody.current = {
      ...searchBody.current,
      'city[]': value,
    };

    if (value) {
      const _districts = await getListDistrict([value]);
      setDistricts(_districts);
    } else {
      setDistricts([]);
    }

    setWards([]);
  }

  async function onChangeDistrict(value: any, callback?: any) {
    callback && callback();

    searchBody.current = {
      ...searchBody.current,
      'district[]': value,
    };
    condition.current = {
      isLoading: true,
      canLoadMore: true,
      currentPage: 0,
    };
    if (value) {
      const _wards = await getListWard([value]);
      setWards(_wards);
    } else {
      setWards([]);
    }
  }

  const onRefresh = () => {
    condition.current = {
      isLoading: false,
      canLoadMore: true,
      currentPage: 1,
    };
    setListBoxes([]);
    fetchListBox();
  };

  async function handleChangeCity(value: any) {
    document.body.style.overflow = 'hidden';
    searchBody.current = {
      ...searchBody.current,
      'city[]': value,
    };
    onChangeCity(value, () => {
      searchBody.current = {
        ...searchBody.current,
        'district[]': undefined,
        'ward[]': undefined,
      };
      form.setFieldValue('district', undefined);
      form.setFieldValue('ward', undefined);
    });
    onRefresh();
  }

  async function handleChangeDistrict(value: any) {
    document.body.style.overflow = 'hidden';
    searchBody.current = {
      ...searchBody.current,
      'district[]': value,
    };

    onChangeDistrict(value, () => {
      searchBody.current = {
        ...searchBody.current,
        'ward[]': undefined,
      };
      form.setFieldValue('ward', undefined);
    });
    onRefresh();
  }

  async function handleChangeWard(value: any) {
    document.body.style.overflow = 'hidden';
    searchBody.current = {
      ...searchBody.current,
      'ward[]': value,
    };
    form.setFieldValue('ward', value);
    onRefresh();
  }

  const fetchListBox = async (isLoadMore?: boolean) => {
    condition.current.isLoading = true;
    const response = await api.getListBoxes({
      ...searchBody.current,
      page: isLoadMore ? condition.current.currentPage : 1,
      pageSize: 10,
    });
    if (response?.ok) {
      const data = response?.data?.data?.items;

      const newSize = data?.length;
      if (newSize > 0) {
        if (isLoadMore) {
          setListBoxes((state) => [...state, ...data]);
        } else {
          setListBoxes(data);
        }
        condition.current.currentPage = condition.current.currentPage + 1;
        condition.current.isLoading = false;
        condition.current.canLoadMore = newSize === 10;
      } else if (!response.ok) {
        setListBoxes([]);
      }
    }
  };
  const onLoadMore = () => {
    if (!condition.current.isLoading && condition.current.canLoadMore) {
      fetchListBox(true);
    }
  };

  const onScroll = (e: any) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === 400) {
      onLoadMore();
    }
  };
  return (
    <CSSTransition
      in={isOpen}
      ref={ref}
      timeout={200}
      classNames="container_modalConfirm_transition"
      unmountOnExit
    >
      <div className="container_modalConfirm">
        <div className="overplay" />
        <div className="content_confirm_wrapper">
          <div className="header">
            <div className="text_header">{title}</div>
            <MdClose size={30} onClick={onClose} />
          </div>
          <div className="content">
            <div>
              <Form form={form}>
                <Col className="table">
                  <Form.Item name="city" rules={[{ required: true }]}>
                    <Select
                      className="custom-select"
                      size="large"
                      placeholder="--Tỉnh/Thành phố--"
                      onChange={handleChangeCity}
                      // showSearch
                      optionFilterProp="children"
                    >
                      {cities?.map((item: any) => {
                        return (
                          <Select.Option value={item?._id} key={item?._id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Row justify={'space-between'} gutter={10}>
                    <Col span={12}>
                      <Form.Item name="district" rules={[{ required: true }]}>
                        <Select
                          // showSearch
                          className="custom-select"
                          size="large"
                          placeholder="--Quận/Huyện--"
                          onChange={handleChangeDistrict}
                          clearIcon
                        >
                          {districts?.map((item: any) => {
                            return (
                              <Select.Option value={item?._id} key={item?._id}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="ward" rules={[{ required: true }]}>
                        <Select
                          // showSearch
                          className="custom-select"
                          size="large"
                          placeholder="--Phường/Xã--"
                          onChange={handleChangeWard}
                        >
                          {wards?.map((item: any) => {
                            return (
                              <Select.Option value={item?._id} key={item?._id}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <div className="item_wrapper">
                    <VirtualList
                      data={listBoxes}
                      height={400}
                      itemKey={'_id'}
                      onScroll={onScroll}
                      virtual
                      itemHeight={50}
                    >
                      {(item: any) => (
                        <List.Item key={`${item?._id}${item?.code}`}>
                          <div
                            key={`${item?._id}${item?.code}`}
                            className="item"
                            onClick={() => handelConfirm(item)}
                          >
                            <b>{item?.name}</b>
                            <div className="infomation">
                              <Ic_location />
                              <span>{item?.address}</span>
                            </div>
                          </div>
                        </List.Item>
                      )}
                    </VirtualList>
                  </div>
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default forwardRef(OrderModal);
