import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import dataFake from '../../helper/dataFake';

import ModalCustom from '../../components/ModalCustom';
import { Img } from 'react-image';
import Images from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import WelcomeScreen from '../WelcomeScreen';
import axios from 'axios';

import './styles.scss';
import ModalPage from '../../components/ModalPage/Pages/ListModal';
import OrderModal from '../../components/ModalPage/Pages/OrderModal';
import ConfirmModal from '../../components/ModalPage/Pages/ConfirmModal';
import MyGiftModal from '../../components/ModalPage/Pages/MyGiftModal';
import { api } from '../../service';
import { BackgroundColors, TextLostTurn } from './types';
import { Button } from 'antd';

const MiniGameScreen = () => {
  const btnRef = useRef<any>();
  const { t } = useTranslation();
  let refCurrent = useRef({
    prizeNumber: 0,
  });
  const navigation = useNavigate();
  const refModalCustom = useRef<any>(null);
  const refPageList = useRef<any>(null);
  const refPageConfirm = useRef<any>(null);
  const refConfirmModal = useRef<any>(null);
  const refMyGiftModal = useRef<any>(null);

  const [dataConvert, setDataConvert] = useState<any>();
  const [data, setData] = useState<any>();
  const [isShow, setIsShow] = useState<boolean>(true);
  const [mustSpin, setMustSpin] = useState<boolean>(false);
  const [isSpin, setIsSpin] = useState<boolean>(false);
  const [turns, setTurns] = useState<number>(3);
  // const [bonus, setBonus] = useState<any>();
  const [giftReceived, setGiftReceived] = useState<any>();
  const [miniGameActive, setMiniGameActive] = useState<any>();

  const fetchGetMiniGameActive = async () => {
    const responsive = await api.getMiniGameActive();
    if (responsive?.ok) {
      await fetchData(responsive?.data?.data?._id);
      setMiniGameActive(responsive?.data?.data);
    } else {
      console.log('error');
    }
  };

  const fetchData = async (_id: any) => {
    const responsive = await api.getMiniGameUser(_id);
    if (responsive?.ok) {
      const covertData = responsive?.data?.data?.pieList?.map(
        (item: any, index: any) => {
          if (item?.name === TextLostTurn) {
            return {
              _id: item?._id,
              images: item?.image,
              quantity: item?.quantity,
              name: item?.name,
              option: item?.name,
              style: { backgroundColor: '#AFAFAF' },
            };
          }
          return {
            _id: item?._id,
            images: item?.image,
            quantity: item?.quantity,
            name: item?.name,
            option: item?.index,
          };
        },
      );
      setTurns(responsive?.data?.data?.userTurnQty);
      setData(responsive?.data?.data);
      setDataConvert(covertData || dataFake);
      setTimeout(() => {
        setIsShow(false);
      }, 500);
    } else {
      toast.error(t(responsive?.data?.message));
    }
  };

  async function getBonusUser() {
    const responsive = await api.getMiniGameResult(miniGameActive?._id);
    const getBonus =
      dataConvert &&
      dataConvert.findIndex(
        (item: any) => item?.name === responsive?.data?.name,
      );

    if (responsive) {
      const res = await api.getUserTurnQty(miniGameActive?._id);
      if (res.ok) {
        setTurns(res?.data?.data?.remainingTurn);
      } else {
        toast.error('error');
      }
      setGiftReceived(responsive?.data);
      if (getBonus !== -1) {
        refCurrent.current.prizeNumber = getBonus;
      }

      if (!mustSpin) {
        setMustSpin(true);
      }
    } else {
      toast.error('error');
    }
  }

  const handleSpinClick = () => {
    if (dataConvert == dataFake) {
      return;
    }
    setIsSpin(true);
    if (turns <= 0 || isSpin) {
      return;
    }
    getBonusUser();
  };

  const handleRejectGift = async (value: any) => {
    const responsive = await api.saveMinigameResult({
      boxId: null,
      giftId: giftReceived?._id,
    });
    if (responsive.ok) {
      value?.current.hide();
    } else {
      console.log('error');
    }
  };

  const onStopSpinning = () => {
    setMustSpin(false);
    if (dataConvert[refCurrent?.current?.prizeNumber]?.name) {
      refModalCustom.current.show({
        dataConvert: dataConvert[refCurrent.current.prizeNumber],
        giftId: giftReceived,
      });
    }
  };

  const handelListGift = () => {
    refPageList.current.show();
  };

  const renderWheelLuck = useMemo(() => {
    return (
      <Wheel
        pointerProps={{
          src: Images.kim,
          style: {
            width: '70px',
            height: '70px',
            top: '10px',
            // display: "none",
          },
        }}
        // perpendicularText={true}
        textDistance={70}
        fontSize={20}
        // fontFamily="Anton"
        spinDuration={0.6}
        outerBorderColor={'tranparent'}
        outerBorderWidth={1}
        radiusLineWidth={3}
        innerBorderColor={'#f2f2f2'}
        radiusLineColor={'#FFEDCA'}
        textColors={['white']}
        mustStartSpinning={mustSpin}
        prizeNumber={refCurrent.current.prizeNumber}
        data={dataConvert}
        disableInitialAnimation={true}
        backgroundColors={BackgroundColors}
        onStopSpinning={onStopSpinning}
      />
    );
  }, [mustSpin, dataConvert]);

  return (
    <div className="container_miniGame">
      {isShow && (
        <WelcomeScreen fetchGetMiniGameActive={fetchGetMiniGameActive} />
      )}
      <div className="logo">
        <Img src={Images.logoOnebox} alt="logo-onebox" className="img_logo" />
      </div>
      <div className="headerWrapper">
        <Img
          src={Images.vongquaymayman}
          alt="logo-onebox"
          className="img_rotation"
        />
        {/* <div className="header">
          <div className="parallelogram"></div>
          <div className="textHeader">CHƠI</div>
        </div>
        <div className="title">Là trúng quà</div> */}
      </div>
      <div className="itemWrapper">
        <div className="turns">
          <div className="turn">{turns} lượt quay</div>
          <Img src={Images.bangso} className="table_number" />
        </div>
        {/* <div className="bonus">Phần quà của bạn là :{bonus?.name}</div> */}
      </div>
      <div className="luckyWheel">
        {dataConvert && (
          <>
            <div className="wheelWrapper">{renderWheelLuck}</div>
            <div className="spinWrapper">
              <Img
                loading="eager"
                src={Images.buttonStart5}
                className={`spin`}
              />
            </div>
            <div className="background"></div>
          </>
        )}
        {/* <div className="overOpacity"></div> */}
        {dataConvert == dataFake ? (
          <div className="visible">
            <div className="isTurn">
              Chương trình hết phần quà
              <br />
              Vui lòng quay lại sau !
            </div>
          </div>
        ) : (
          !mustSpin &&
          turns <= 0 && (
            <div className="visible">
              <div className="isTurn">Đã hết lượt quay</div>
            </div>
          )
        )}
        {/* {turns <= 0 && dataConvert == dataFake && (
          <div className="visible">
            <div className="isTurn">Đã hết lượt quay</div>
          </div>
        )} */}
      </div>

      <div className="controllerWrapper">
        <div className="button_controller_wrapper" onClick={handelListGift}>
          <Img src={Images.gift} className="button_controller" />
          <div className="text_gift">Xem quà</div>
        </div>
        <div className="buttonSpin" onClick={handleSpinClick} ref={btnRef} />
        <div
          className="button_controller_wrapper"
          onClick={() => refMyGiftModal.current.show()}
        >
          <Img src={Images.my_gift1} className="button_controller" />
          <div className="text_gift">Quà của tôi</div>
        </div>
      </div>
      <ModalCustom
        onConfirm={() => {
          setIsSpin(false);
          refModalCustom.current.hide();
          refPageConfirm.current.show();
        }}
        onClose={() => {
          setIsSpin(false);
          refModalCustom.current.hide();
        }}
        ref={refModalCustom}
        // data={bonus}
      />
      <ModalPage
        data={data?.flatList}
        onClose={() => refPageList.current.hide()}
        ref={refPageList}
        title="Danh sách nhận thưởng"
      />
      <OrderModal
        onConFirm={(value) => {
          refPageConfirm.current.hide();
          refConfirmModal.current.show({
            giftReceived: dataConvert[refCurrent?.current?.prizeNumber],
            cabinetLocation: value,
          });
        }}
        onClose={() => handleRejectGift(refPageConfirm)}
        ref={refPageConfirm}
        title="Chọn tủ nhận quà"
      />
      <ConfirmModal
        onClose={() => {
          refConfirmModal.current.hide();
          refPageConfirm.current.show();
        }}
        ref={refConfirmModal}
        title="Xác nhận thông tin"
        dataUser={data?.user}
      />
      <MyGiftModal
        onClose={() => refMyGiftModal.current.hide()}
        ref={refMyGiftModal}
        title="Quà của tôi"
      />
      <Toaster />
    </div>
  );
};

export default React.memo(MiniGameScreen);
