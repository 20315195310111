import { createBrowserRouter } from "react-router-dom";
import WelcomeScreen from "../pages/WelcomeScreen";
import MiniGameScreen from "../pages/MiniGameScreen";
import OrderScreen from "../pages/OrderScreen";
import ListGiftScreen from "../pages/ListGiftScreen";
import ConfirmScreen from "../pages/ConfirmScreen";
import CongratulationsScreen from "../pages/CongratulationsScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MiniGameScreen />,
  },
  // {
  //   path: "/",
  //   element: <WelcomeScreen />,
  // },
  {
    path: "/Order",
    element: <OrderScreen />,
  },
  {
    path: "/ListGift",
    element: <ListGiftScreen />,
  },
  {
    path: "/Confirm",
    element: <ConfirmScreen />,
  },
  {
    path: "/Congratulations",
    element: <CongratulationsScreen />,
  },
]);

export default router;
