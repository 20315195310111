import React, { useEffect, useMemo, useState } from 'react';
import './styles.css';
import anime from 'animejs';
import { motion } from 'framer-motion';
import {
  Link,
  redirect,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import router from '../../routers';
import { api } from '../../service';

interface Props {
  fetchGetMiniGameActive: () => void;
}

const WelcomeScreen = ({ fetchGetMiniGameActive }: Props) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const [queryParameters] = useSearchParams();

  const [data, setData] = useState<any>(null);
  const [process, setProcess] = useState<any>();

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (process === 99 && data) {
  //     console.log("hello");
  //   }
  // }, [process, data]);

  useEffect(() => {
    getData(queryParameters.get('accessToken') || '');
  }, [queryParameters]);

  async function saveAccessToken(accessToken?: string) {
    if (accessToken) {
      await localStorage.setItem('accessToken', accessToken);
      return;
    }
  }

  async function getData(accessToken: string) {
    const responsive = await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([1, 2, 4]);
      }, 500);
    });
    if (responsive) {
      await saveAccessToken(accessToken);
      fetchGetMiniGameActive();
      // navigate('/MiniGame');
    } else {
      alert('Lỗi hệ thống');
    }
  }

  useEffect(() => {
    anime({
      targets: '.staggering-easing-demo .el',
      translateY: `${windowHeight - 20}px`,
      delay: anime.stagger(300, { easing: 'easeOutQuad' }),
      duration: 1600,
    });
    anime({
      delay: 1700,
      targets: '.staggering-easing-demo .box',
      translateY: `${windowHeight - 20}px`,
      scale: 2,
      duration: 1400,
      rotate: '1turn',
    });
    const procesAnimation = anime({
      targets: '.process',
      width: '140px', // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 3000,
      update: (anim) => {
        const converProcess = Math.floor(anim.progress);
        if (converProcess === 99) {
          procesAnimation.pause();
          // getData();
        }
        setProcess(Math.floor(anim.progress));
      },
      complete: () => {
        console.log('success');
      },
    });
  }, []);

  const renderAnimation = useMemo(() => {
    return (
      <div className="staggering-easing-demo">
        <div>
          <svg
            className="box"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.225 15.55L66.5438 33.2562L50 40.3437L8.91875 22.7375C9.84375 21.7937 10.975 21.0312 12.2625 20.5375L25.225 15.55ZM33.5875 12.3375L43.275 8.61249C47.6059 6.94753 52.4003 6.94753 56.7312 8.61249L87.7437 20.5375C89.025 21.0375 90.1562 21.7875 91.0812 22.7375L74.4812 29.85L33.5875 12.3375ZM93.7125 28.4125L53.125 45.8062V92.3687C54.3571 92.161 55.5652 91.8301 56.7312 91.3812L87.7437 79.45C89.5118 78.7692 91.0321 77.5687 92.1043 76.0067C93.1764 74.4447 93.7502 72.5946 93.75 70.7V29.2875C93.7512 28.9952 93.7366 28.7032 93.7063 28.4125H93.7125ZM46.875 92.3687V45.8062L6.29375 28.4125C6.26558 28.7033 6.25099 28.9953 6.25 29.2875V70.7C6.25025 72.5953 6.82495 74.446 7.89832 76.0081C8.97168 77.5701 10.4933 78.7702 12.2625 79.45L43.2687 91.3812C44.4437 91.8312 45.65 92.1625 46.875 92.3687Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="el">O</div>
        <div className="el">n</div>
        <div className="el">e</div>
        <div className="el">B</div>
        <div className="el">o</div>
        <div className="el">x</div>
      </div>
    );
  }, []);

  return (
    <div className="containerWelcome">
      {renderAnimation}
      <div className="containerProcess">
        <div>
          <div className="processWrapper">
            <div className="process"></div>
          </div>
          <div className="valueProcess">{process}%</div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
