import React from "react";

import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

import "./styles.scss"

interface TitleHeaderProps {
  title: string;
}
const TitleHeader = ({ title }: TitleHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="titleHeader">
      <FiArrowLeft
        size={30}
        className="icon_back"
        onClick={() => navigate(-1)}
      />
      <div className="text_header">{title}</div>
    </div>
  );
};

export default TitleHeader;
