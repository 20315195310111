import React, { CSSProperties } from 'react';

import './styles.scss';
import { Button } from 'antd';

interface ButtonProps {
  loading?: boolean;
  onClick?: () => void;
  className?: string;
  title: any;
  mode?: 'primary' | 'danger';
  style?: CSSProperties;
}
const ButtonCustom = ({
  loading,
  onClick,
  className,
  title,
  mode = 'primary',
  style,
}: ButtonProps) => {
  return (
    <div className="button_container">
      <Button
        type="text"
        className={`${mode} ${className}`}
        style={style}
        onClick={onClick}
        loading={loading}
      >
        {title && title}
      </Button>
    </div>
  );
};

export default ButtonCustom;
