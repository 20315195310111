import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'antd';
import toast from 'react-hot-toast';
import { Img } from 'react-image';
import { CSSTransition } from 'react-transition-group';

import Images from '../../../../assets/images';
import { MdClose } from 'react-icons/md';
import dataFake from '../../../../helper/dataFake';
import Ic_arrow from '../../../../assets/icons/icon_arrow';
import Ic_location_small from '../../../../assets/icons/icon_location_small';
import Ic_location from '../../../../assets/icons/icon_location';

import './styles.scss';
import { api } from '../../../../service';
import { useNavigate } from 'react-router-dom';
import ButtonCustom from '../../../ButtonCustom';

interface ModalPageProps {
  title: string;
  onClose: () => void;
  onConFirm?: (value?: any) => void;
  dataUser?: any;
}

const ModalConfirm = (
  { title, onClose, onConFirm, dataUser }: ModalPageProps,
  ref: any,
) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        show: (data?: any) => {
          setData(data);
          setIsOpen(true);
          document.body.style.overflow = 'hidden';
        },
        hide: () => {
          setIsOpen(false);
          document.body.style.overflow = 'auto';
        },
      };
    },
    [],
  );
  const handleSaveGift = async () => {
    setLoading(true);
    const responsive = await api.saveMinigameResult({
      boxId: data?.cabinetLocation?._id,
      giftId: data?.giftReceived?._id,
    });
    setLoading(false);
    if (responsive.ok) {
      ref.current.hide();
      navigation('/Congratulations', {
        state: responsive?.data?.data,
      });
    } else {
      console.log('error');
    }
  };

  return (
    <>
      <CSSTransition
        in={isOpen}
        ref={ref}
        timeout={200}
        classNames="container_modalConfirm_transition"
        unmountOnExit
      >
        {/* {isOpen && ( */}
        <div className="container_modalConfirm">
          <div className="overplay" />
          <div className="content_list_wrapper">
            <div className="header">
              <Ic_arrow onClick={onClose} />
              <b className="text_header">{title}</b>
              {/* <MdClose size={30} onClick={onClose} /> */}
            </div>
            <div className="content">
              <div className="user_wrapper">
                <b className="name">{dataUser?.fullName}</b>
                <div>{dataUser?.phoneNumber}</div>
              </div>
              <div className="location_wrapper">
                <b className="location">{data?.cabinetLocation?.name}</b>
                <div className="distance_warpper">
                  <Ic_location_small />
                  <span className="text_distance">
                    {`${data?.cabinetLocation?.address || ''},
                    ${data?.cabinetLocation?.district?.name || ''},
                    ${data?.cabinetLocation?.city?.name || ''}`}
                  </span>
                </div>
                <div className="btn_change_location_wrapper">
                  <div className="btn_change_location">
                    <Ic_location />
                    <b onClick={onClose}>Đổi địa chỉ nhận quà</b>
                  </div>
                </div>
              </div>
              <div className="gifts_received_wrapper">
                <div className="header_gift_wrapper">
                  <Img src={Images.gift} className="gift" />
                  <b className="header_gift">Quà nhận được</b>
                </div>
                <div className="content_gift_wrapper">
                  <div className="item_wrapper">
                    <div className="item">
                      <Img src={data?.giftReceived?.images} alt="botgiat" className="img" />
                    </div>
                    <div className="text_item">{data?.giftReceived?.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='btn_confirm'>
          <ButtonCustom
            mode="primary"
            onClick={handleSaveGift}
            loading={loading}
            title={'Xác nhận'}
          />
          </div>
        </div>
        {/* )} */}
      </CSSTransition>
    </>
  );
};

export default forwardRef(ModalConfirm);
