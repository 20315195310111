import botgiat from './botgiat.png';
import xemay from './xemay.png';
import khantam from './khantam.png';
import tambong from './tambong.png';
import tienmat100k from './100k.png';
import tienmat200k from './200k.png';
import tienmat500k from './500k.png';
import background from './background.jpg';
import background2 from './background2.jpg';
import background3 from './background3.jpg';
import background4 from './background4.jpg';
import background5 from './background5.png';
import buttonSpin from './buttonSpin.png';
import startButton from './startButton.png';
import startButtonDrak from './startButtonDrak.png';
import logoOnebox from './logoOnebox.png';
import buttonStart from './buttonStart.png';
import buttonStart2 from './buttonStart2.png';
import buttonStart5 from './buttonStart5.png';
import buttonStart_animation from './buttonStart_animation.png';
import effect_bonus from './effect_bonus.png';
import see_gifts from './see_gifts.png';
import my_gift from './my_gift.png';
import icon_gift from './icon_gift.png';
import prenium1 from './prenium1.png';
import prenium2 from './prenium2.png';
import congratulations from './congratulations.png';
import vongquaymayman from './vongquaymayman.png';
import rim from './background10.png';
import kim from './kim.png';
import bangso from './bangso.png';
import buttonStart3 from './buttonStart3.png';
import buttonStart4 from './buttonStart4.png';
import gift from './gift.png';
import my_gift1 from './my_gift1.png';
import icLostTurn from './ic_lost_turn.png';
import logo from './logo.png';
// import prenium3 from "./prenium3.png";

const Images: any = {
  botgiat,
  xemay,
  khantam,
  tambong,
  tienmat100k,
  tienmat200k,
  tienmat500k,
  background,
  background2,
  background3,
  background4,
  background5,
  buttonSpin,
  startButton,
  startButtonDrak,
  logoOnebox,
  buttonStart,
  buttonStart_animation,
  effect_bonus,
  my_gift,
  see_gifts,
  prenium1,
  prenium2,
  icon_gift,
  congratulations,
  vongquaymayman,
  rim,
  kim,
  buttonStart2,
  bangso,
  buttonStart3,
  buttonStart4,
  buttonStart5,
  gift,
  my_gift1,
  icLostTurn,
  logo,
};

export default Images;
