import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import './styles.scss';
import { Img } from 'react-image';
import { CSSTransition } from 'react-transition-group';

import Images from '../../../../assets/images';
import { MdClose } from 'react-icons/md';
import Ic_location_small from '../../../../assets/icons/icon_location_small';
import { api } from '../../../../service';
import { formatDateStringToDD_MM_YYYY } from '../../../../helper/formatDate';

interface ModalPageProps {
  title: string;
  onClose: () => void;
}

const ModalMyGift = ({ title, onClose }: ModalPageProps, ref: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  async function fetchData() {
    const responsive = await api.listMiniGameUserResult();
    if (responsive?.ok) {
      setData(responsive?.data?.data);
    } else {
      console.log('error');
    }
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        show: () => {
          fetchData();
          setIsOpen(true);
          document.body.style.overflow = 'hidden';
        },
        hide: () => {
          setIsOpen(false);
          document.body.style.overflow = 'auto';
        },
      };
    },
    [],
  );

  const renderPendingGift = useMemo(() => {
    return (
      <div className="gift_pending_wrapper">
        <h3>QUÀ ĐANG CHỜ XỬ LÝ</h3>
        {data?.waitingProcess?.map((item: any) => {
          return (
            <div className="item_wrapper" key={item?._id}>
              <div className="flex">
                <div className="img_wrapper">
                  <Img
                    src={item?.giftId?.image || Images.logo}
                    className="img"
                  />
                </div>
                <div className="content_item_wrapper">
                  <div className="content_item">
                    <b className="name">{item?.giftId?.name}</b>
                    <b className="status">Đang xử lý</b>
                  </div>
                  <div className="date">
                    {formatDateStringToDD_MM_YYYY(item?.createdAt)}
                  </div>
                </div>
              </div>
              <div className="location_wrapper">
                <div className="ic_location">
                  <Ic_location_small />
                </div>
                <span className="location">{item?.boxId?.address}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [data]);

  const renderCompleteGift = useMemo(() => {
    return (
      <div className="gift_complete_wrapper">
        <h3>QUÀ ĐÃ NHẬN</h3>
        {data?.completes?.map((item: any) => {
          return (
            <div className="item_wrapper" key={item?._id}>
              <div className="flex">
                <div className="img_wrapper">
                  <Img
                    src={item?.giftId?.image || Images.logo}
                    className="img"
                  />
                </div>
                <div className="content_item_wrapper">
                  <div className="content_item">
                    <b className="name">{item?.giftId?.name}</b>
                  </div>
                  <div className="date">
                    {formatDateStringToDD_MM_YYYY(item?.createdAt)}
                  </div>
                </div>
              </div>
              <div className="location_wrapper">
                <Ic_location_small />
                <span className="location">{item?.boxId?.address}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [data]);

  return (
    <CSSTransition
      in={isOpen}
      ref={ref}
      timeout={200}
      classNames="container_modalMyGift_transition"
      unmountOnExit
    >
      {/* {isOpen && ( */}
      <div className="container_modalMyGift">
        <div className="overplay" onClick={onClose} />
        <div className="content_list_wrapper">
          <div className="header">
            <Img src={Images.my_gift1} className="img_header" />
            <b className="text_header">{title}</b>
            <MdClose size={30} onClick={onClose} />
          </div>
          <div className="content">
            {renderPendingGift}
            {renderCompleteGift}
          </div>
        </div>
      </div>
      {/* )} */}
    </CSSTransition>
  );
};

export default forwardRef(ModalMyGift);
