import { Img } from 'react-image';
import './styles.scss';
import Images from '../../assets/images';
import Button from '../../components/ButtonCustom';
import Ic_location from '../../assets/icons/icon_location';
import Ic_location_small from '../../assets/icons/icon_location_small';
import ButtonCustom from '../../components/ButtonCustom';
import { useLocation, useNavigate } from 'react-router-dom';
import MyGiftModal from '../../components/ModalPage/Pages/MyGiftModal';
import { useEffect, useRef } from 'react';

const CongratulationsScreen = () => {
  const location = useLocation();
  const { state } = location;
  const refMyGiftModal = useRef<any>(null);
  const navigation = useNavigate();

  const handelNavigateTo = () => {
    navigation('/');
  };

  return (
    <div className="congratulations_container">
      <div className="wheel_luck">
        <Img src={Images.vongquaymayman} />
      </div>
      <div className="content_wrapper">
        <h3>Chúc mừng!</h3>
        <br />
        <b className="name">{state?.userId?.fullName}</b>
        <br />
        <span>{state?.userId?.phoneNumber}</span>
        <br />
        <b>Địa chỉ tủ:</b>
        <br />
        <span>
          <Ic_location_small /> {state?.boxId?.address}
        </span>
        <br />
        <span>Đã nhận được:</span>
        <br />
        <h3>{state?.giftId?.name}</h3>
        <Img src={state?.giftId?.image || Images.logo} className="img" />
      </div>
      <div className="btn_wrapper">
        <ButtonCustom
          onClick={() => refMyGiftModal.current.show()}
          title={'Lịch sử nhận quà'}
          mode="danger"
        />
        <ButtonCustom
          title={'Tiếp tục quay thưởng'}
          mode="primary"
          onClick={handelNavigateTo}
        />
      </div>
      <MyGiftModal
        onClose={() => refMyGiftModal.current.hide()}
        ref={refMyGiftModal}
        title="Quà của tôi"
      />
    </div>
  );
};

export default CongratulationsScreen;
