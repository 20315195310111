import React from "react";

import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import StepForwardOutlined from "@ant-design/icons";
import { TiLocation } from "react-icons/ti";
import { FiArrowLeft } from "react-icons/fi";

import "./styles.scss";
import Item from "../../components/Item";
import ButtonConfirm from "../../components/ButtonConfirm";
import TitleHeader from "../../components/TitleHeader";

const OrderScreen = () => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="orderContainer">
      <TitleHeader title="Chọn tủ nhận quà"/> 
      <Form
        className="form_order"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Col className="location_wrapper">
          <Col span={24}>
            <Form.Item name={"city"}>
              <Select
                size="large"
                placeholder={"--Tỉnh/Thành phố -- "}
                onChange={handleChange}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                ]}
              />
            </Form.Item>
          </Col>

          <Row justify={"space-between"}>
            <Col span={11}>
              <Form.Item name={"district"}>
                <Select
                  placeholder={"-- Quận/Huyện -- "}
                  size="large"
                  onChange={handleChange}
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                    { value: "Yiminghe", label: "yiminghe" },
                    { value: "disabled", label: "Disabled", disabled: true },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item name={"district"}>
                <Select
                  placeholder={"-- Phường/Xã --"}
                  size="large"
                  onChange={handleChange}
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                    { value: "Yiminghe", label: "yiminghe" },
                    { value: "disabled", label: "Disabled", disabled: true },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Input
              size="large"
              addonBefore={<TiLocation />}
              placeholder="Chọn vị trí tủ nhận quà "
            />
          </Form.Item>
        </Col>

        <Col>
          <div className="title_cabinet">TỦ GẦN BẠN</div>
          <div className="list_cabinet">
            <Item
              data={{
                name: "Tủ Khâm Thiên 60",
                location: "63 Xuân Diệu, Quảng An, Tây Hồ, Hà Nội",
                distance: "50 m",
              }}
            />
            <Item
              data={{
                name: "Tủ Khâm Thiên 60",
                location: "63 Xuân Diệu, Quảng An, Tây Hồ, Hà Nội",
                distance: "50 m",
              }}
            />
          </div>
        </Col>
        <ButtonConfirm onConfirm={onFinish} />
      </Form>
    </div>
  );
};

export default OrderScreen;
