export const TextLostTurn = 'Mất lượt';
export const BackgroundColors = [
  '#1ABC9C',
  '#16A085',
  '#27AE60',
  '#5867BA',
  '#2ECC71',
  '#2980B9',
  '#8E44AD',
  '#9B59B6',
  '#2C3E50',
  '#D35400',
  '#E74C3C',
  '#D35400',
  '#E67E22',
  '#F39C12',
  '#E67480',
  '#E67480',
  '#B47E6C',
  '#848DC5',
  '#14577E',
  '#848DC5',
];
