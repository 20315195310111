import { GrLocation } from "react-icons/gr";
import TitleHeader from "../../components/TitleHeader";

import "./styles.scss";
import { Img } from "react-image";
import Images from "../../assets/images";
import ButtonConfirm from "../../components/ButtonConfirm";
import { useNavigate } from "react-router-dom";

const ConfirmScreen = () => {
const navigate=useNavigate()

  return (
    <div className="confirmScreen">
      <TitleHeader title="Xác nhận thông tin" />
      <div className="content_confirm">
        <div className="item">
          <div className="text">Trần Lê Hùng</div>
          <div className="title">0919234567</div>
        </div>
        <div className="item">
          <div className="text_small">Tủ Khâm Thiên 60</div>
          <div className="title_location">
            <GrLocation className="icon_location" />
            63 Xuân Diệu, Quảng An, Tây Hồ, Hà Nội
          </div>
          <div className="btn_change_location_wrapper">
            <div className="btn_change_location">
              <GrLocation className="icon_location" />
              Đổi địa chỉ nhận quà
            </div>
          </div>
        </div>
        <div className="item">
          <div className="text_small">
            <Img src={Images.icon_gift} className="icon_gift" />
            Quà nhận được
          </div>
          <div className="my_gift">
            <Img src={Images.botgiat} className="img_my_gift" />
            <div className="content_my_gift">
              <div>Xe Đạp Địa Hình MTB Pacific Vigilon 3.0 27.5 inch</div>
            </div>
          </div>
        </div>
      </div>
      <ButtonConfirm onConfirm={() => navigate("/Congratulations")} />
    </div>
  );
};

export default ConfirmScreen;
