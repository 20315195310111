interface Props {
  width?: string | number;
  height?: string | number;
}

const Ic_location_small = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9364 6.68651C12.9364 10.3226 6.99986 16.3333 6.99986 16.3333C6.99986 16.3333 1.06335 10.3226 1.06335 6.68651C1.06335 2.90198 4.10581 0.75 6.99986 0.75C9.89391 0.75 12.9364 2.90198 12.9364 6.68651Z"
        stroke="url(#paint0_linear_25174_888)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M6.99987 8.9127C8.22936 8.9127 9.22606 7.916 9.22606 6.68651C9.22606 5.45702 8.22936 4.46032 6.99987 4.46032C5.77038 4.46032 4.77368 5.45702 4.77368 6.68651C4.77368 7.916 5.77038 8.9127 6.99987 8.9127Z"
        stroke="url(#paint1_linear_25174_888)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <defs>
        <linearGradient
          id="paint0_linear_25174_888"
          x1="6.99986"
          y1="0.75"
          x2="6.99986"
          y2="16.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFFFBB" />
          <stop offset="0.296875" stop-color="#EC9C5F" />
          <stop offset="0.552083" stop-color="white" stop-opacity="0.53125" />
          <stop offset="0.802083" stop-color="#EC9C5F" />
          <stop offset="1" stop-color="#FFFFBB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_25174_888"
          x1="6.99987"
          y1="4.46032"
          x2="6.99987"
          y2="8.9127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFFFBB" />
          <stop offset="0.296875" stop-color="#EC9C5F" />
          <stop offset="0.552083" stop-color="white" stop-opacity="0.53125" />
          <stop offset="0.802083" stop-color="#EC9C5F" />
          <stop offset="1" stop-color="#FFFFBB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Ic_location_small;
