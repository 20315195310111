import React from "react";

import "./styles.scss";

interface ButtonConfirmProps {
  onConfirm: (value:any) => void;
}
const ButtonConfirm = ({ onConfirm }: ButtonConfirmProps) => {
  return (
    <div className="buttonConfirm_container" onClick={onConfirm}>
      <div className="button_wrapper">
      Xác nhận
      </div>
    </div>
  );
};

export default ButtonConfirm;
