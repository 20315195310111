import React from "react";
import { Img } from "react-image";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import Images from "../../assets/images";
import dataFake from "../../helper/dataFake";

import "./styles.scss";

const ListGiftScreen = () => {
  const navigate = useNavigate();

  const handelClose = () => {
    navigate(-1);
  };

  return (
    <div className="listGiftWrapper">
      <div className="header_wrapper">
        <Img src={Images.icon_gift} className="icon_gift" />
        <div className="text_header">Danh sách phần thưởng</div>
        <div className="close" onClick={handelClose}>
          <CloseOutlined />
        </div>
      </div>
      <div className="listGift">
        {dataFake.map((item: any) => {
          return (
            <div className="item">
              <div className="item_left">
                <b className="option">{item?.option}</b>
                {(item.option == "1" ||
                  item.option == "2" ||
                  item.option == "3") && (
                  <Img src={Images?.prenium1} className="img_armorial" />
                )}
                <div className="nameProduct">{item?.name}</div>
              </div>
              <div className="item_right">
                <Img src={Images?.[item?.nameImage]} className="img_product" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ListGiftScreen;
