const Ic_location = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_24979_49446)">
        <path
          d="M12.0002 3C8.74802 3 5.34802 5.51304 5.34802 9.65217C5.34802 13.5696 11.2611 19.5565 11.4828 19.7783C11.6306 19.9261 11.7785 20 12.0002 20C12.2219 20 12.3698 19.9261 12.5176 19.7783C12.7393 19.5565 18.6524 13.5696 18.6524 9.65217C18.6524 5.51304 15.2524 3 12.0002 3ZM12.0002 11.8696C10.7437 11.8696 9.7828 10.9087 9.7828 9.65217C9.7828 8.39565 10.7437 7.43478 12.0002 7.43478C13.2567 7.43478 14.2176 8.39565 14.2176 9.65217C14.2176 10.9087 13.2567 11.8696 12.0002 11.8696Z"
          fill="url(#paint0_linear_24979_49446)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_24979_49446"
          x1="12.0002"
          y1="3"
          x2="12.0002"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFFFBB" />
          <stop offset="0.223958" stop-color="#FFA216" />
          <stop offset="0.505208" stop-color="white" />
          <stop offset="0.734375" stop-color="#FFBE16" />
          <stop offset="1" stop-color="#FFFFBB" />
        </linearGradient>
        <clipPath id="clip0_24979_49446">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ic_location;
