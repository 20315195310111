import { GrLocation } from "react-icons/gr";
import { MdMyLocation } from "react-icons/md";

import "./styles.scss";

interface ItemProps {
  data: any;
}

const Item = ({ data }: ItemProps) => {
  return (
    <div className="item_container">
      <div className="name_cabinet">{data?.name}</div>
      <div className="item_location_wrapper">
        <GrLocation className="icon_location" size={20} />
        <div className="name_location">{data?.location}</div>
      </div>
      <div className="item_location_wrapper">
        <MdMyLocation className="icon_location" size={20} />
        <div className="name_location">{data?.distance}</div>
      </div>
    </div>
  );
};

export default Item;
