import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import toast from 'react-hot-toast';

import './styles.scss';
import { Img } from 'react-image';
import { CSSTransition } from 'react-transition-group';
import Images from '../../../../assets/images';
import { MdClose } from 'react-icons/md';
import dataFake from '../../../../helper/dataFake';
import { api } from '../../../../service';

interface ModalPageProps {
  title: string;
  onClose: () => void;
  data: any;
}

const ModalPage = ({ title, onClose, data }: ModalPageProps, ref: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        show: () => {
          setIsOpen(true);
          document.body.style.overflow = 'hidden';
        },
        hide: () => {
          setIsOpen(false);
          document.body.style.overflow = 'auto';
        },
      };
    },
    [],
  );

  return (
    <>
      <CSSTransition
        in={isOpen}
        ref={ref}
        timeout={200}
        classNames="container_modalPage_transition"
        unmountOnExit
      >
        {/* <>
        {isOpen && ( */}
        <div className="container_modalPage">
          <div className="overplay" onClick={onClose} />
          <div className="content_list_wrapper">
            <div className="header">
              <Img src={Images.gift} className="img_header" />
              <b className="text_header">{title}</b>
              <MdClose size={30} onClick={onClose} />
            </div>
            <div className="content">
              <Row className="item">
                <Col span={3}>
                  <b>STT</b>
                </Col>
                <Col span={17}>
                  <b>Tên sản phẩm</b>
                </Col>
                <Col span={4}>
                  <b>Còn lại</b>
                </Col>
              </Row>
              {data &&
                data?.map((item: any) => {
                  return (
                    <Row
                      key={item?._id}
                      gutter={[0, 0]}
                      style={{
                        paddingBottom: '20px',
                        color: item?.remainingQty == 0 ? 'gray' : 'white',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* <div className="item" key={item?._id}> */}
                      {/* <div className="item_left">
                          <div className="img_product">
                            <Img src={item?.image} className="img_prenium" />
                          </div> */}
                      <Col span={3}>
                        <div className="index">{item?.index}</div>
                      </Col>
                      <Col span={17}>
                        <Row align={'middle'} justify={'start'}>
                          <Img
                            src={item?.image || Images.logo}
                            style={{
                              width: 30,
                              height: 30,
                              marginRight: 10,
                            }}
                          />
                          <div
                            style={{
                              fontSize:
                                item?.remainingQty == 0 ? '12px' : '14px',
                            }}
                          >
                            {item?.name}
                          </div>
                        </Row>
                      </Col>
                      {/* </div> */}
                      <Col span={4}>
                        <div>{item?.remainingQty}</div>
                      </Col>
                      {/* </div> */}
                    </Row>
                  );
                })}
            </div>
          </div>
        </div>
        {/* )}
      </> */}
      </CSSTransition>
    </>
  );
};

export default forwardRef(ModalPage);
