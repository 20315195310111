import apisauce from 'apisauce';
import { SaveMiniGame, SignInBody } from './api-types';
import axios from 'axios';

const URL_STAGING = 'https://api.infinityvalley.vn/api';

const api = apisauce.create({
  baseURL: URL_STAGING,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 10000,
});

function CreateApi() {
  async function getMiniGameUser(params: any): Promise<any> {
    const token = await localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.get(
      '/get-mini-game-user',
      {
        miniGameId: params,
      },
      config,
    );
    return response;
  }

  async function getMiniGameResult(params: string): Promise<any> {
    try {
      const token = await localStorage.getItem('accessToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get(
        '/get-minigame-result',
        { miniGameId: params },
        config,
      );

      return response.data;
    } catch (error: any) {
      console.error('Error:', error.message);
    }
  }
  async function getListCity(): Promise<any> {
    try {
      const token = await localStorage.getItem('accessToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get('/get-list-city', {}, config);

      return response.data;
    } catch (error: any) {
      console.error('Error:', error.message);
    }
  }
  async function getListWard(wardId: any): Promise<any> {
    const accessToken = await localStorage.getItem('accessToken');
    const response = await api.get(
      `/get-list-ward?districtId[]=${wardId[0]}`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
    return response;
  }
  async function getListDistrict(
    cityId: Array<string | undefined>,
  ): Promise<any> {
    const accessToken = await localStorage.getItem('accessToken');

    const response = await api.get(
      `get-list-district?cityId[]=${cityId}`,
      { cityId },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
    return response;
  }
  async function getListBoxes(query: any): Promise<any> {
    const accessToken = await localStorage.getItem('accessToken');
    console.log(accessToken);

    const response = await api.get(`get-box-list`, query, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response;
  }

  async function saveMinigameResult(params: SaveMiniGame): Promise<any> {
    const token = await localStorage.getItem('accessToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.post('/save-minigame-result', params, config);

    return response;
  }

  async function getMiniGameActive(): Promise<any> {
    const token = await localStorage.getItem('accessToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.get('/get-mini-game-active', {}, config);

    return response;
  }

  async function listMiniGameUserResult(): Promise<any> {
    const token = await localStorage.getItem('accessToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.get('/list-minigame-user-result', {}, config);

    return response;
  }

  async function getUserTurnQty(params: any): Promise<any> {
    const token = await localStorage.getItem('accessToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.get(
      '/get-user-turn-qty',
      { miniGameId: params },
      config,
    );

    return response;
  }

  return {
    getMiniGameUser,
    getMiniGameResult,
    getListCity,
    getListWard,
    getListDistrict,
    getListBoxes,
    saveMinigameResult,
    getMiniGameActive,
    listMiniGameUserResult,
    getUserTurnQty,
  };
}

export default CreateApi;
