import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import './styles.scss';
import { Img } from 'react-image';
import Images from '../../assets/images';
import ButtonCustom from '../ButtonCustom';
import { useNavigate } from 'react-router-dom';
import { TextLostTurn } from '../../pages/MiniGameScreen/types';
import { api } from '../../service';
import { Spin } from 'antd';

interface Props {
  // data: any;
  onClose?: any;
  onConfirm: () => void;
}
const ModalCustom = ({ onClose, onConfirm }: Props, ref: any) => {
  const [data, setData] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        show: (value: any) => {
          setData(value);
          setIsOpen(true);
          document.body.style.overflow = 'hidden';
        },
        hide: () => {
          setIsOpen(false);
          document.body.style.overflow = 'auto';
        },
      };
    },
    [],
  );

  const handleRejectGift = async () => {
    setLoading(true);
    const responsive = await api.saveMinigameResult({
      boxId: null,
      giftId: data?.giftId?._id,
    });
    setLoading(false);
    if (responsive.ok) {
      onClose();
    } else {
      console.log('error');
    }
  };

  return (
    <>
      {isOpen && (
        <div className="modalCustom_container">
          <div className="overplay"></div>
          <div className="content">
            {data?.dataConvert?.name === TextLostTurn ? (
              <>
                <Img className="img_lostTurn" src={Images.icLostTurn} />
                <b className="text_content">
                  Ôi không! Bạn đã quay vào ô {data?.dataConvert?.name}.
                </b>
                <div className="btn_wrapper">
                  <ButtonCustom
                    title={'Quay tiếp'}
                    mode="primary"
                    onClick={onClose}
                  />
                </div>
              </>
            ) : (
              <>
                <Img src={Images.effect_bonus} className="img" />
                <b className="text_content">
                  Chúc mừng bạn đã trúng thưởng {data?.dataConvert?.name}
                </b>
                <Img src={data?.dataConvert?.images} className="img" loader={<Spin size='large'/>}/>
                <span className="text_content">
                  Bạn có muốn nhận phần quà này không?
                </span>
                <div className="btn_wrapper">
                  <ButtonCustom
                    title={'Từ chối'}
                    mode="danger"
                    onClick={handleRejectGift}
                    loading={loading}
                  />
                  <ButtonCustom
                    title={'Đồng ý'}
                    mode="primary"
                    onClick={onConfirm}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalCustom);
